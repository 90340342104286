import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useGlobalState } from "../../store";
import Noaccount from "./noaccount";
import Account from "./accounts";

function Positions() {
  const [Accounts] = useGlobalState("accounts");
  const [positions] = useGlobalState("bybitMPositions");

  useEffect(() => {}, [positions]);

  const account = Accounts.filter((account) => account.exchange === "BYBITM");
  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Manual
              </li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">Manual</h4>
        </div>
      </div>
      <div class="row row-xs">
        {account.length === 0 && <Noaccount />}
        {account.length > 0 &&
          account.map((acct, index) => <Account key={index} account={acct} />)}
      </div>
    </>
  );
}

export default Positions;
