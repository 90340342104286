import React from "react";
import io from "socket.io-client";
import {
  useGlobalState,
  getGlobalState,
  setGlobalState,
  fetchAll,
} from "./store";
const socket = io("/");

socket.on("BITMEX_XBT", (price) => {
  setGlobalState("XBT", price);
});
socket.on("BITMEX_ETH", (price) => {
  setGlobalState("ETH", price);
});
socket.on("bybit_btc", (price) => {
  //console.log('BTC', price)
  setGlobalState("BTC", price);
});

socket.on("bybit", (message) => {
  if (message.table === "tickers") {
    let tickers = getGlobalState("tickers");
    if (message.data.symbol === "BTCUSD") {
      setGlobalState("XBT", message.data.last_price_e4 / Math.pow(10, 4));
    }
    setGlobalState("tickers", { ...tickers, ...message.data });
  }
  if (message.table === "positions") {
    let positions = getGlobalState("bybitPositions");
    message.data.forEach((item) => {
      if (item.mode === "BothSide") {
        let index = positions.findIndex(
          (pos) =>
            pos.symbol === item.symbol &&
            pos.user_id === item.user_id &&
            pos.side === item.side
        );
        if (index > -1) {
          positions[index] = item;
        } else {
          positions.push(item);
        }
      } else {
        let index = positions.findIndex(
          (pos) => pos.symbol === item.symbol && pos.user_id === item.user_id
        );
        if (index > -1) {
          positions[index] = item;
        } else {
          positions.push(item);
        }
      }
    });
    setGlobalState("bybitPositions", positions);
  }
  if (message.table === "wallets") {
    let wallets = getGlobalState("bybitWallets");
    let user_id = message.account;
    if (user_id !== undefined) {
      wallets = wallets.filter((w) => w.user_id !== user_id);
      wallets = [...wallets, ...message.data];
    } else {
      message.data.forEach((item) => {
        let index = wallets.findIndex(
          (w) => w.user_id === item.user_id && w.currency === item.currency
        );
        if (index > -1) {
          wallets[index] = item;
        } else {
          wallets.push(item);
        }
      });
    }
    setGlobalState("bybitWallets", wallets);
  }
});
socket.on("bybitm", (message) => {
  if (message.table === "positions") {
    setGlobalState("bybitMPositions", message.data);
  }
  if (message.table === "wallets") {
    setGlobalState("bybitMWallets", message.data);
  }
});

socket.on("ftxus", (message) => {
  if (message.table == "wallets") {
    let wallets = getGlobalState("ftxusWallets");
    wallets = wallets.filter((w) => w.user_id !== message.account);
    wallets.push(...message.data);
    setGlobalState("ftxusWallets", wallets);
  }
});

socket.on("sync-accounts", () => {
  fetch("/api/accounts")
    .then((res) => res.json())
    .then((accounts) => setGlobalState("accounts", accounts));
});

socket.on("connect", () => {
  let update_state = getGlobalState("update_state");
  if (update_state) {
    fetchAll();
  } else {
    setGlobalState("update_state", true);
  }
});

export { socket, useGlobalState };
