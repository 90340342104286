import React, { useState } from "react";
import { useGlobalState } from "../../store";
import { Trash2 } from "react-feather";
function PositionTable({ account, positions }) {
  const deletePosition = (id) => {
    fetch(`api/position/${id}`, { method: "DELETE" }).then((res) => {});
  };
  const rows = positions.map((row, index) => (
    <tr key={index}>
      <td className="text-center tx-medium">{row.symbol}</td>
      <td className="text-center tx-medium">{row.side}</td>
      <td className="text-center tx-medium">{row.size}</td>
      <td className="text-center tx-medium">{row.entry_price}</td>
      <td class="tx-small text-center">
        <Trash2 onClick={(e) => deletePosition(row.id)} />
      </td>
    </tr>
  ));
  return (
    <div class="table-responsive">
      <table class="table table-dashboard mg-b-0">
        <thead>
          <tr>
            <th class="text-center">Symbol</th>
            <th class="text-center">Side</th>
            <th class="text-center">Size</th>
            <th class="text-center">Price</th>
            <th class="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default PositionTable;
