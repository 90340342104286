import React, { useState, useEffect } from "react";
import { useGlobalState } from "../../store";
import PositionTable from "./positionTable";
import PositionForm from "./positionForm";
import WalletTable from "./walletTable";
import WalletForm from "./walletForm";

function Account({ account }) {
  const [AllPositions] = useGlobalState("bybitMPositions");
  const [AllWallets] = useGlobalState("bybitMWallets");
  const [showPositionForm, setShowPositionFome] = useState(false);
  const [showForm, setShowFome] = useState(false);
  const positions = AllPositions.filter((p) => p.user_id === account.id);
  const wallets = AllWallets.filter((p) => p.user_id === account.id);

  return (
    <div class="col-lg-12 mg-t-10">
      <div class="card mg-b-10">
        <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
          <div>
            <h5 class="mg-b-5">{account.title}</h5>
          </div>
          <div>
            <h5
              onClick={(e) => setShowPositionFome(!showPositionForm)}
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-b-5"
            >
              New Position
            </h5>
            <h5
              onClick={(e) => setShowFome(!showForm)}
              className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-b-5 mg-l-5"
            >
              New Wallet
            </h5>
          </div>
        </div>
        <div class="card-body pd-y-30">
          {!showPositionForm && !showForm && (
            <>
              <div class="card mg-b-10">
                <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <h5 class="mg-b-5">Wallets</h5>
                </div>
                <div class="card-body pd-y-30">
                  <WalletTable account={account} wallets={wallets} />
                </div>
              </div>
              <div class="card mg-b-10">
                <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <h5 class="mg-b-5">Positions</h5>
                </div>
                <div class="card-body pd-y-30">
                  <PositionTable account={account} positions={positions} />
                </div>
              </div>
            </>
          )}
          {showPositionForm && (
            <div class="card-body pd-y-30">
              <PositionForm
                account={account}
                setShowForm={setShowPositionFome}
              />
            </div>
          )}
          {showForm && (
            <div class="card-body pd-y-30">
              <WalletForm account={account} setShowForm={setShowFome} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Account;
