import React from "react";

function Noaccount() {
  return (
    <div class="col-lg-12 mg-t-10">
      <div class="card mg-b-10">
        <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
          <div>
            <h5 class="mg-b-5">No Manual Account, Please add one</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Noaccount;
