import React, { useState } from "react";
import { useGlobalState } from "../../store";
import { Trash2 } from "react-feather";
function WalletTable({ account, wallets }) {
  const deleteWallet = (id) => {
    fetch(`api/wallet/${id}`, { method: "DELETE" }).then((res) => {});
  };
  const rows = wallets.map((row, index) => (
    <tr key={index}>
      <td className="text-center tx-medium">{row.currency}</td>
      <td className="text-center tx-medium">{row.wallet_balance}</td>
      <td class="tx-small text-center">
        <Trash2 onClick={(e) => deleteWallet(row.id)} />
      </td>
    </tr>
  ));
  return (
    <div class="table-responsive">
      <table class="table table-dashboard mg-b-0">
        <thead>
          <tr>
            <th class="text-center">Currency</th>
            <th class="text-center">Balance</th>
            <th class="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
}

export default WalletTable;
