import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  client: "",
  loading: true,
  user: "",
  logged_in: false,
  positionSummary: [],
  XBT: 0,
  ETH: 0,
  BTC: 0,
  tickers: {
    BTCUSD: { price: 0 },
    ETHUSD: { price: 0 },
    EOSUSD: { price: 0 },
    XRPUSD: { price: 0 },
  },
  bitmexPositions: [],
  bybitPositions: [],
  bybitMPositions: [],
  bitmexWallets: [],
  bybitWallets: [],
  bybitMWallets: [],
  ftxusWallets: [],
  accounts: [],
  portfolio: [],
  update_state: false,
};
const { useGlobalState, getGlobalState, setGlobalState } =
  createGlobalState(initialState);

const fetchAll = () => {
  fetch("/api/tickers")
    .then((res) => res.json())
    .then((tickers) => setGlobalState("tickers", tickers))
    .catch((err) => {
      console.log(err);
    });

  fetch("/api/wallets")
    .then((res) => res.json())
    .then((wallets) => {
      //console.log(wallets);
      setGlobalState("bybitWallets", wallets.bybit);
      setGlobalState("bitmexWallets", wallets.bitmex);
      setGlobalState("ftxusWallets", wallets.ftxus);
      setGlobalState("bybitMWallets", wallets.bybitm);
    })
    .catch((err) => {
      console.log(err);
    });
  fetch("/api/positions")
    .then((res) => res.json())
    .then((positions) => {
      setGlobalState("bitmexPositions", positions.bitmex);
      setGlobalState("bybitPositions", positions.bybit);
      setGlobalState("bybitMPositions", positions.bybitm);
    })
    .catch((err) => {
      console.log(err);
    });

  fetch("/api/accounts")
    .then((res) => res.json())
    .then((accounts) => setGlobalState("accounts", accounts))
    .catch((err) => {
      console.log(err);
    });
};

fetch("/api/me")
  .then((res) => res.json())
  .then((res) => {
    //console.log(res)
    fetchAll();
    setGlobalState("user", res.user);
    setGlobalState("client", res.client);
    setGlobalState("logged_in", true);
    setGlobalState("loading", false);
  })
  .catch((err) => {
    //console.log(err)
    setGlobalState("loading", false);
    setGlobalState("logged_in", false);
  });

const get_price = (symbol) => {
  let tickers = getGlobalState("tickers");
  symbol = symbol.replace("XBT", "BTC");
  try {
    if (symbol === "USDT") {
      return 1;
    }
    if (tickers[symbol]) {
      return tickers[symbol].price;
    }
    if (tickers[`${symbol}USDT`]) {
      return tickers[`${symbol}USDT`].price;
    } else {
      return 1;
    }
  } catch (err) {
    console.log(`ticker error ${symbol}`);
    return 1;
  }
};

export { useGlobalState, getGlobalState, setGlobalState, fetchAll, get_price };
