import React, { useState } from "react";

function WalletForm({ account, setShowForm }) {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    user_id: account.id,
    currency: "",
    wallet_balance: 0,
  });
  const handleInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    const fetchResult = await fetch("api/wallets", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        user_id: formData.user_id,
        currency: formData.currency,
        wallet_balance: formData.wallet_balance,
      }), // body data type must match "Content-Type" header
    });
    if (fetchResult.ok) {
      setDisabled(false);

      setFormData({
        user_id: account.id,
        currency: "",
        wallet_balance: 0,
      });
      setShowForm(false);
    } else {
      console.log("error");
      setDisabled(false);
      setError(true);
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div class="form-group">
          <label for="formGroupExampleInput" class="d-block">
            Currency
          </label>
          <input
            type="text"
            autoComplete="off"
            name="currency"
            disabled={disabled}
            class="form-control"
            placeholder="USDT"
            onChange={handleInput}
            value={formData.currency}
          />
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput2" class="d-block">
            Balance
          </label>
          <input
            type="number"
            name="wallet_balance"
            disabled={disabled}
            class="form-control"
            required
            placeholder="0.1"
            onChange={handleInput}
            value={formData.wallet_balance}
          />
        </div>

        <button class="btn btn-primary" type="submit" disabled={disabled}>
          {disabled && (
            <span
              class="spinner-border spinner-border-sm mg-r-5"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>
        <button
          onClick={(e) => {
            setShowForm(false);
          }}
          class="btn btn-secondary mg-l-5"
          type="cancel"
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default WalletForm;
