import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function NewAccount() {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [api_key, setApi_key] = useState("");
  const [exchange, setExchange] = useState("");
  const [api_secret, setApi_secret] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [remove_withdrawal, set_remove_withdrawal] = useState(false);
  const [error, setError] = useState(null);
  const [strategy_start_date, set_strategy_start_date] = useState(null);
  const onSubmit = async (e) => {
    setDisabled(true);
    setError(null);
    e.preventDefault();
    const fetchResult = await fetch("api/accounts", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        title: title,
        api_key: api_key,
        api_secret: api_secret,
        exchange: exchange,
        strategy_start_date: strategy_start_date,
        remove_withdrawal: remove_withdrawal,
      }), // body data type must match "Content-Type" header
    });
    console.log("call done");
    if (fetchResult.ok) {
      navigate("/settings");
    } else {
      setDisabled(false);
      const result = await fetchResult.json();
      //console.log(result)
      setError(result);
    }
  };
  return (
    <>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                New Account
              </li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">New Account</h4>
        </div>
        <div class="d-none d-md-block">
          <Link
            to="/settings"
            class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
          >
            {" "}
            Accounts
          </Link>
        </div>
      </div>
      <div class="col-lg-12 col-xl-12 mg-t-10">
        <div class="card card-body">
          {error !== null && (
            <div class="alert alert-danger" role="alert">
              {error.error.message}
            </div>
          )}
          <form onSubmit={onSubmit}>
            <div class="form-group">
              <label for="formGroupExampleInput" class="d-block">
                Account Title
              </label>
              <input
                type="text"
                autocomplete="off"
                disabled={disabled}
                class="form-control"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput" class="d-block">
                Exchange
              </label>
              <select
                required
                disabled={disabled}
                class="form-control custom-select"
                value={exchange}
                onChange={(e) => setExchange(e.target.value)}
              >
                <option value="">---------</option>
                <option value="BITMEX">BitMEX</option>
                <option value="BYBIT">ByBit</option>
                <option value="BYBITM">ByBit Manual</option>
                <option value="FTXUS">FTXUS</option>
              </select>
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput2" class="d-block">
                API KEY
              </label>
              <input
                autocomplete="off"
                type="text"
                disabled={disabled}
                class="form-control"
                required
                placeholder="API KEY"
                value={api_key}
                onChange={(e) => setApi_key(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="formGroupExampleInput2" class="d-block">
                API SECRET
              </label>
              <input
                autocomplete="off"
                type="text"
                disabled={disabled}
                class="form-control"
                required
                placeholder="API SECRET"
                value={api_secret}
                onChange={(e) => setApi_secret(e.target.value)}
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-6">
                  <label for="formGroupExampleInput2" class="d-block">
                    Strategy start Date
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={strategy_start_date}
                    onChange={(date) => set_strategy_start_date(date)}
                  />
                </div>
                <div class="col-6 align-self-end">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked={remove_withdrawal}
                      onChange={(e) => set_remove_withdrawal(e.target.checked)}
                      class="custom-control-input"
                      id="customSwitch1"
                    />
                    <label class="custom-control-label" for="customSwitch1">
                      Remove withdrawals from equity chart?
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit" disabled={disabled}>
              {disabled && (
                <span
                  class="spinner-border spinner-border-sm mg-r-5"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
            <Link to="/settings" class="btn btn-secondary mg-l-5" type="cancel">
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}
