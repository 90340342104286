import React, { useState } from "react";

function PositionForm({ account, setShowForm }) {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    user_id: account.id,
    symbol: "",
    size: 0,
    side: "",
    entry_price: 0,
  });
  const handleInput = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    const fetchResult = await fetch("api/positions", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        user_id: formData.user_id,
        symbol: formData.symbol,
        side: formData.side,
        size: formData.size,
        entry_price: formData.entry_price,
      }), // body data type must match "Content-Type" header
    });
    if (fetchResult.ok) {
      setDisabled(false);

      setFormData({
        user_id: account.id,
        symbol: "",
        size: 0,
        side: "",
        entry_price: 0,
      });
      setShowForm(false);
    } else {
      console.log("error");
      setDisabled(false);
      setError(true);
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div class="form-group">
          <label for="formGroupExampleInput" class="d-block">
            Symbol
          </label>
          <input
            type="text"
            autoComplete="off"
            name="symbol"
            disabled={disabled}
            class="form-control"
            placeholder="BTCUSDT"
            onChange={handleInput}
            value={formData.symbol}
          />
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput" class="d-block">
            Side
          </label>
          <select
            required
            name="side"
            disabled={disabled}
            class="form-control custom-select"
            onChange={handleInput}
            value={formData.side}
          >
            <option value="">---------</option>
            <option value="Buy">Buy</option>
            <option value="Sell">Sell</option>
          </select>
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput2" class="d-block">
            Size
          </label>
          <input
            type="number"
            name="size"
            disabled={disabled}
            class="form-control"
            required
            placeholder="0.1"
            onChange={handleInput}
            value={formData.size}
          />
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput2" class="d-block">
            Price
          </label>
          <input
            type="number"
            name="entry_price"
            disabled={disabled}
            class="form-control"
            required
            placeholder="10000"
            onChange={handleInput}
            value={formData.entry_price}
          />
        </div>

        <button class="btn btn-primary" type="submit" disabled={disabled}>
          {disabled && (
            <span
              class="spinner-border spinner-border-sm mg-r-5"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>
        <button
          onClick={(e) => {
            setShowForm(false);
          }}
          class="btn btn-secondary mg-l-5"
          type="cancel"
        >
          Cancel
        </button>
      </form>
    </div>
  );
}

export default PositionForm;
